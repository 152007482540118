import React, { useState } from 'react';
import TableManager from '../../components/TableManager';
import useIcons from '../../assets/icons/useIcons';
import AsideModal from '../../components/AsideModal';
import AddAgency from './AddAgency';
import { getAgency } from '../../api';
import Dropdown from '../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helpers/functions';
import useDashBoardLogin from '../../hooks/useDashBoardLogin';

const filterInitialValues = {
  filter: [
    {
      key: 'firstName',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Name',
      inputType: 'text',
      inputLabel: 'Name',
    },

    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
    {
      key: 'phone',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Phone#',
      inputType: 'phone-number',
      inputLabel: 'Phone',
    },
    {
      key: 'NPN',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter NPN',
      inputType: 'number',
      inputLabel: 'NPN',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function Agency() {
  const [addMember, setAddMember] = useState(false);
  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { SuccessOutlineIcon, MoreVIcon, CrossIcon, EyeIcon } = useIcons();

  const navigate = useNavigate();
  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};
    switch (value) {
      case 'view':
        navigate(`/user-info/${_id}/agency-profile`, { state: data });
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);
        break;
      default:
        break;
    }
  };
  const columns = [
    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'full_name',
          accessorFn: (row) => `${row.firstName} ${row.lastName}`,
          header: () => 'Full Name',
          cell: (props) => {
            const element = props.row.original;
            return (
              <span className="c--pointer text--danger font--600 d--flex gap--sm d--flex align-items--center justify-content--start">
                {element?.status === 'ACTIVATE' ? (
                  <div
                    data-tooltip={'Active'}
                    tooltip-position="right"
                    className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
                  >
                    A
                  </div>
                ) : (
                  <div
                    data-tooltip={'Deactive'}
                    tooltip-position="right"
                    className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
                  >
                    D
                  </div>
                )}
                {props.getValue()}
              </span>
            );
          },
          meta: {
            textAlign: 'left',
          },
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
        },
        {
          accessorKey: 'phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'NPN',
          header: () => 'NPN',
          meta: {
            textAlign: 'center',
          },
        },

        {
          accessorKey: 'verifySSN',
          header: () => 'SSN ',
          meta: {
            textAlign: 'center',
          },
          cell: (props) => {
            const element = props.row.original;
            return (
              <span className="c--pointer text--danger font--600 d--flex justify-content--center">
                {element?.verifySSN ? (
                  <div
                    data-tooltip={'Verified'}
                    tooltip-position="top"
                    className="bg--success radius--full  w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                  >
                    <SuccessOutlineIcon width={22} height={22} />
                  </div>
                ) : (
                  <div
                    data-tooltip={'Not Verified'}
                    tooltip-position="top"
                    className="bg--danger radius--full w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                  >
                    <CrossIcon height={22} width={22} />
                  </div>
                )}
              </span>
            );
          },
        },
        {
          accessorKey: 'verifyNPN',
          header: () => 'NPN ',
          meta: {
            textAlign: 'center',
          },
          cell: (props) => {
            return (
              <span className="c--pointer text--danger font--600 d--flex justify-content--center">
                {props.getValue() ? (
                  <div
                    data-tooltip={'Verified'}
                    tooltip-position="top"
                    className="bg--success radius--full w-min--14 w-max--14 h-min--14 h-max--14  text--white d--flex justify-content--center align-items--center"
                  >
                    <SuccessOutlineIcon width={22} height={22} />
                  </div>
                ) : (
                  <div
                    data-tooltip={'Not Verified'}
                    tooltip-position="top"
                    className="bg--danger radius--full w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                  >
                    <CrossIcon height={22} width={22} />
                  </div>
                )}
              </span>
            );
          },
        },
        {
          accessorKey: 'hraAgreement',
          header: () => 'HRA Signed ',
          meta: {
            textAlign: 'center',
          },
          cell: (props) => {
            const element = props.getValue();
            if (element?.documentStatus === 'NO') {
              return (
                <div className="d--flex justify-content--center align-items--center gap--xs">
                  <span className="c--pointer text--danger font--600 d--flex justify-content--center">
                    <div
                      data-tooltip={'NOT SIGNED'}
                      tooltip-position="top"
                      className="bg--danger radius--full w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                    >
                      <CrossIcon height={22} width={22} />
                    </div>
                  </span>
                  <span
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_PORTAL_URL}hra-agreement-sign-doc/${element?.uuId}`,
                      );
                    }}
                    className="c--pointer  d--flex"
                  >
                    <EyeIcon width={18} height={18} />
                  </span>
                </div>
              );
            } else if (element?.documentStatus === 'SIGNED') {
              return (
                <div className="d--flex justify-content--center align-items--center gap--xs">
                  <span className="c--pointer text--danger font--600 d--flex justify-content--center">
                    <div
                      data-tooltip={element?.documentStatus}
                      tooltip-position="top"
                      className="bg--success radius--full w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                    >
                      <SuccessOutlineIcon height={22} width={22} />
                    </div>
                  </span>
                  <span
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_PORTAL_URL}hra-agreement-sign-doc/${element?.uuId}`,
                      );
                    }}
                    className="c--pointer  d--flex"
                  >
                    <EyeIcon width={18} height={18} />
                  </span>
                </div>
              );
            } else {
              return (
                <span className="c--pointer text--danger font--600 d--flex justify-content--center">
                  <div
                    data-tooltip={'Not Sent'}
                    tooltip-position="top"
                    className="bg--danger radius--full w-min--14 w-max--14 h-min--14 h-max--14 text--white d--flex justify-content--center align-items--center"
                  >
                    <CrossIcon height={22} width={22} />
                  </div>
                </span>
              );
            }
          },
        },
      ],
      meta: {
        colSpan: 7,
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">HRA</span>,
      columns: [
        {
          accessorKey: 'hraSend',
          header: () => 'Sent',
          cell: (props) => {
            return (
              <div
                onClick={() => {}}
                className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
              >
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#00008B',
          },
        },
        {
          accessorKey: 'hraCompleted',
          header: () => 'Completed',
          cell: (props) => {
            return (
              <div
                onClick={() => {}}
                className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
              >
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#00008B',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#00008B',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consent</span>,
      columns: [
        {
          accessorKey: 'consentSend',
          header: () => 'Sent',
          cell: (props) => {
            return (
              <div
                onClick={() => {}}
                className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
              >
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'consentSigned',
          header: () => 'Signed',
          cell: (props) => {
            return (
              <div
                onClick={() => {}}
                className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
              >
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#1a9112',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Eligibility</span>,
      columns: [
        {
          accessorKey: 'eligibilitySend',
          header: () => 'Sent',
          cell: (props) => (
            <div
              onClick={() => {}}
              className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
            >
              {props.getValue()}
            </div>
          ),

          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
        {
          accessorKey: 'eligibilitySigned',
          header: () => 'Signed',
          cell: (props) => {
            return (
              <div
                onClick={() => {}}
                className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
              >
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#ffa000',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => '',
      columns: [
        {
          accessorKey: 'createdAt',
          header: () => 'Registration On',
          cell: (props) => formatDate(props.getValue()),
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'action',
          header: () => 'Action',
          cell: (props) => {
            const element = props?.row?.original;
            return (
              <div className="gap--xs w--full d--flex align-items--center justify-content--center">
                <Dropdown
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => (
                      <span
                        className="w--full d--flex  justify-content--start p--sm"
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {item?.title}
                      </span>
                    ),
                    data: [
                      // { title: 'View', value: 'view' },
                      { title: 'Go to dashboard', value: 'go_to_dashboard' },
                    ],
                  }}
                  showcaret={false}
                >
                  <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                    <MoreVIcon width={15} height={15} />
                  </div>
                </Dropdown>
              </div>
            );
          },
          meta: {
            colType: 'action',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
        colSpan: 2,
      },
    },
  ];

  const handleClose = () => {
    setEditUserInstance({ isEdit: false, editData: {} });
    setAddMember(false);
  };

  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'getAgency',
          fetcherFn: getAgency,
          columns,
          name: 'Agency',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.users,
        }}
      />
      {addMember && (
        <AsideModal
          handleClose={handleClose}
          title="User"
          footerComponent={null}
        >
          <AddAgency
            editUserInstance={editUserInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </div>
  );
}
