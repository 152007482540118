import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import { AGENCY_USER, AGENT, MEMBER } from '../../helpers/constants';

export default function Sidebar({ userDetail = {} }) {
  const { userInfo: { firstName, lastName, role } = {} } = userDetail || {};
  console.log('🚀 ~ Sidebar ~ role:', role);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    GroupIcon,
    BackLongIcon,
    ProfileIcon,
    ConsumerIcon,
    ClaimIcon,
    InviteIcon,
    ProgramIcon,
    SendInviteIcon,
    UserIcon,
  } = useIcons();
  const tabs = [
    {
      id: 0,
      label: 'Dashboard',
      icon: ProfileIcon,
      path: 'agent-dashboard',
      access: true,
      // access: [AGENT].includes(role),
    },
    // {
    //   id: 1,
    //   label: 'Profile',
    //   icon: ProfileIcon,
    //   path: 'agent-profile',
    //   access: [AGENT].includes(role),
    // },
    // {
    //   id: 1.1,
    //   label: 'Profile',
    //   icon: ProfileIcon,
    //   path: 'agency-profile',
    //   access: [AGENCY_USER].includes(role),
    // },
    // {
    //   id: 1.2,
    //   label: 'Profile',
    //   icon: ProfileIcon,
    //   path: 'consumer-profile',
    //   access: [MEMBER].includes(role),
    // },

    // {
    //   id: 2,
    //   label: 'Consumer',
    //   icon: ConsumerIcon,
    //   path: 'consumer',
    //   access: [AGENT, AGENCY_USER].includes(role),
    // },
    // {
    //   id: 3,
    //   label: 'Claims',
    //   icon: ClaimIcon,
    //   path: 'claims',
    //   access: [AGENT, AGENCY_USER].includes(role),
    // },
    // {
    //   id: 4,
    //   label: 'Accept Invite',
    //   icon: SendInviteIcon,
    //   path: 'invite',
    //   access: [AGENT].includes(role),
    // },
    // {
    //   id: 5,
    //   label: 'Send Invite',
    //   icon: InviteIcon,
    //   path: 'send-invite',
    //   access: [AGENCY_USER].includes(role),
    // },
    // {
    //   id: 6,
    //   label: 'HRA Program',
    //   icon: ProgramIcon,
    //   path: 'agent-HRA',
    //   access: [AGENT, AGENCY_USER].includes(role),
    // },

    // {
    //   id: 7,
    //   label: 'Agent',
    //   icon: UserIcon,
    //   path: 'agent',
    //   access: [MEMBER].includes(role),
    // },
    // {
    //   id: 8,
    //   label: 'HRA Program',
    //   icon: ProgramIcon,
    //   path: 'consumer-HRA',
    //   access: [MEMBER].includes(role),
    // },
  ];

  const handleActiveClass = (active) => {
    if (active === location.pathname.split('/').at(-1)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <aside className="d--flex w--full w-max--200 h--full  text--white p-t--md p-r--md p-l--md p-b--md flex--column gap--sm bg--black-50">
      <div className="w--full d--flex  justify-content--start align-items--start gap--md font--md font--600 text--black m-b--md flex--column p-b--md border-bottom--black-100">
        <span
          className="c--pointer d--flex font--md font--500 gap--sm align-items--center text--danger"
          onClick={() => navigate('/user')}
        >
          <BackLongIcon /> Back
        </span>
        <div className="d--flex flex--column m-l--sm align-items--center">
          {firstName} {lastName}
        </div>
      </div>
      <div className="d--flex flex--column gap--md w--full h--full">
        {tabs.map(({ id, label, icon: Icon, path, access = true }) => {
          if (!access) return;
          return (
            <NavLink
              key={id}
              to={path}
              data-link-hover-black-100
              className={`${
                handleActiveClass(path) ? 'bg--black-100 ' : ' text--black-800'
              }  d--flex align-items--center justify-content--start  d--flex gap--sm font--sm font--500 p--sm radius--sm h-min--40 text--black-800`}
            >
              <Icon width={24} height={24} /> {label}
            </NavLink>
          );
        })}
      </div>
    </aside>
  );
}
