import { apiClient } from './axios';
import { routes } from './routes';

export const login = (data) => {
  return apiClient({
    method: routes.LOGIN.METHOD,
    url: routes.LOGIN.URL,
    data,
  });
};
export const getProfileDetails = (data) => {
  return apiClient({
    method: routes.GET_PROFILE_DETAILS.METHOD,
    url: routes.GET_PROFILE_DETAILS.URL,
    data,
  });
};

//agent
export const getUsers = (data) => {
  return apiClient({
    method: routes.GET_AGENT.METHOD,
    url: routes.GET_AGENT.URL,
    data,
  });
};
export const dashboardLogin = (data) => {
  return apiClient({
    method: routes.DASHBOARD_LOGIN.METHOD,
    url: routes.DASHBOARD_LOGIN.URL,
    data,
  });
};
//agent view

export const agentConsumerList = (data) => {
  return apiClient({
    method: routes.AGENT_CONSUMER_LIST.METHOD,
    url: routes.AGENT_CONSUMER_LIST.URL,
    data,
  });
};
export const agentClaimList = (data) => {
  return apiClient({
    method: routes.AGENT_CLAIM_LIST.METHOD,
    url: routes.AGENT_CLAIM_LIST.URL,
    data,
  });
};
export const agentInviteList = (data) => {
  return apiClient({
    method: routes.AGENT_INVITE_LIST.METHOD,
    url: routes.AGENT_INVITE_LIST.URL,
    data,
  });
};
export const agentHRAList = (data) => {
  return apiClient({
    method: routes.AGENT_HRA_LIST.METHOD,
    url: routes.AGENT_HRA_LIST.URL,
    data,
  });
};
export const hraDetails = (data) => {
  return apiClient({
    method: routes.HRA_DETAILS.METHOD,
    url: routes.HRA_DETAILS.URL,
    data,
  });
};

//agency
export const getAgency = (data) => {
  return apiClient({
    method: routes.GET_AGENCY.METHOD,
    url: routes.GET_AGENCY.URL,
    data,
  });
};

//consumer
export const getConsumer = (data) => {
  return apiClient({
    method: routes.GET_CONSUMER.METHOD,
    url: routes.GET_CONSUMER.URL,
    data,
  });
};

//consumer view
export const getConsumerAgentList = (data) => {
  return apiClient({
    method: routes.GET_CONSUMER_AGENT_LIST.METHOD,
    url: routes.GET_CONSUMER_AGENT_LIST.URL,
    data,
  });
};
export const getConsumerHRAList = (data) => {
  return apiClient({
    method: routes.GET_CONSUMER_HRA_LIST.METHOD,
    url: routes.GET_CONSUMER_HRA_LIST.URL,
    data,
  });
};

export const getCarrier = (data) => {
  return apiClient({
    method: routes.GET_CARRIER.METHOD,
    url: routes.GET_CARRIER.URL,
    data,
  });
};
export const getClaims = (data) => {
  return apiClient({
    method: routes.GET_Claims.METHOD,
    url: routes.GET_Claims.URL,
    data,
  });
};
export const getAllUsers = (data) => {
  return apiClient({
    method: routes.GET_ALL_USERS.METHOD,
    url: routes.GET_ALL_USERS.URL,
    data,
  });
};
export const getSMS = (data) => {
  return apiClient({
    method: routes.GET_SMS.METHOD,
    url: routes.GET_SMS.URL,
    data,
  });
};
export const getCalls = (data) => {
  return apiClient({
    method: routes.GET_CALLS.METHOD,
    url: routes.GET_CALLS.URL,
    data,
  });
};
export const addUser = (data) => {
  return apiClient({
    method: routes.ADD_USER.METHOD,
    url: routes.ADD_USER.URL,
    data,
  });
};
export const addCarrier = (data) => {
  return apiClient({
    method: routes.ADD_CARRIER.METHOD,
    url: routes.ADD_CARRIER.URL,
    data,
  });
};
export const updateUser = (data) => {
  return apiClient({
    method: routes.UPDATE_USER.METHOD,
    url: routes.UPDATE_USER.URL,
    data,
  });
};
export const updateCarrier = (data) => {
  return apiClient({
    method: routes.UPDATE_CARRIER.METHOD,
    url: routes.UPDATE_CARRIER.URL,
    data,
  });
};
export const userActivation = (data) => {
  return apiClient({
    method: routes.USER_ACTIVATION.METHOD,
    url: routes.USER_ACTIVATION.URL,
    data,
  });
};
export const userDecavitation = (data) => {
  return apiClient({
    method: routes.USER_DEACTIVATION.METHOD,
    url: routes.USER_DEACTIVATION.URL,
    data,
  });
};

export const logout = () => {
  return apiClient({
    method: routes.LOGOUT.METHOD,
    url: routes.LOGOUT.URL,
  });
};
export const adminDashboardStatus = (data) => {
  return apiClient({
    method: routes.ADMIN_DASHBOARD_STATUS.METHOD,
    url: routes.ADMIN_DASHBOARD_STATUS.URL,
    data,
  });
};
export const didListing = (data) => {
  return apiClient({
    method: routes.DID_LISTING.METHOD,
    url: routes.DID_LISTING.URL,
    data,
  });
};
export const supportListing = (data) => {
  return apiClient({
    method: routes.SUPPORT_LISTING.METHOD,
    url: routes.SUPPORT_LISTING.URL,
    data,
  });
};
export const releaseDid = (data) => {
  return apiClient({
    method: routes.DID_RELEASE.METHOD,
    url: routes.DID_RELEASE.URL,
    data,
  });
};

//TRANSACTION
export const transactionListing = (payload) => {
  return apiClient({
    method: routes.TRANSACTION_LISTING.METHOD,
    url: routes.TRANSACTION_LISTING.URL,
    data: payload,
  });
};

export const providerListing = (payload) => {
  return apiClient({
    method: routes.PROVIDER_LISTING.METHOD,
    url: routes.PROVIDER_LISTING.URL,
    data: payload,
  });
};
export const addProvider = (payload) => {
  return apiClient({
    method: routes.ADD_PROVIDER.METHOD,
    url: routes.ADD_PROVIDER.URL,
    data: payload,
  });
};
export const updateProvider = (payload) => {
  return apiClient({
    method: routes.UPDATE_PROVIDER.METHOD,
    url: routes.UPDATE_PROVIDER.URL,
    data: payload,
  });
};
export const deleteProvider = (payload) => {
  return apiClient({
    method: routes.DELETE_PROVIDER.METHOD,
    url: routes.DELETE_PROVIDER.URL,
    data: payload,
  });
};
export const rateCardList = (payload) => {
  return apiClient({
    method: routes.RATECARD_LISTING.METHOD,
    url: routes.RATECARD_LISTING.URL,
    data: payload,
  });
};
export const addRateCard = (payload) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: routes.ADD_RATECARD.METHOD,
    url: routes.ADD_RATECARD.URL,
    data: payload,
  });
};
export const updateRateCard = (payload) => {
  return apiClient({
    method: routes.UPDATE_RATECARD.METHOD,
    url: routes.UPDATE_RATECARD.URL,
    data: payload,
  });
};
export const deleteRateCard = (payload) => {
  return apiClient({
    method: routes.DELETE_RATECARD.METHOD,
    url: routes.DELETE_RATECARD.URL,
    data: payload,
  });
};
export const callRatesListing = (payload) => {
  return apiClient({
    method: routes.CALL_RATES_LISTING.METHOD,
    url: routes.CALL_RATES_LISTING.URL,
    data: payload,
  });
};

//Dropdown api(s)
export const rateCardDropdown = (payload) => {
  return apiClient({
    method: routes.RATE_CARD_DROPDOWN.METHOD,
    url: routes.RATE_CARD_DROPDOWN.URL,
    data: payload,
  });
};
export const providerDropdown = (payload) => {
  return apiClient({
    method: routes.PROVIDER_DROPDOWN.METHOD,
    url: routes.PROVIDER_DROPDOWN.URL,
    data: payload,
  });
};
export const planDropdown = (payload) => {
  return apiClient({
    method: routes.PLAN_DROPDOWN.METHOD,
    url: routes.PLAN_DROPDOWN.URL,
    data: payload,
  });
};
export const addPlan = (payload) => {
  return apiClient({
    method: routes.ADD_PLAN.METHOD,
    url: routes.ADD_PLAN.URL,
    data: payload,
  });
};
export const updatePlan = (payload) => {
  return apiClient({
    method: routes.UPDATE_PLAN.METHOD,
    url: routes.UPDATE_PLAN.URL,
    data: payload,
  });
};
export const getPlans = (payload) => {
  return apiClient({
    method: routes.GET_PLANS.METHOD,
    url: routes.GET_PLANS.URL,
    data: payload,
  });
};
// --------------
export const getUserAllDIDDropdown = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_ALL_DID_DROPDOWN.METHOD,
    url: routes.GET_AGENT_ALL_DID_DROPDOWN.URL,
    data: payload,
  });
};
export const getAgentProfileDetails = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_PROFILE_DETAILS.METHOD,
    url: routes.GET_AGENT_PROFILE_DETAILS.URL,
    data: payload,
  });
};
export const getAgentMembers = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_MEMBERS.METHOD,
    url: routes.GET_AGENT_MEMBERS.URL,
    data: payload,
  });
};
export const getAgentAgents = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_AGENTS.METHOD,
    url: routes.GET_AGENT_AGENTS.URL,
    data: payload,
  });
};
export const getAgentAgencies = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_AGENCIES.METHOD,
    url: routes.GET_AGENT_AGENCIES.URL,
    data: payload,
  });
};
export const getAgentAgenciesMembers = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_AGENCIES_MEMBERS.METHOD,
    url: routes.GET_AGENT_AGENCIES_MEMBERS.URL,
    data: payload,
  });
};
export const getAgentCalls = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_CALLS.METHOD,
    url: routes.GET_AGENT_CALLS.URL,
    data: payload,
  });
};
export const getAgentBillingDetails = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_BILLING_DETAILS.METHOD,
    url: routes.GET_AGENT_BILLING_DETAILS.URL,
    data: payload,
  });
};
export const getAgentPlanDetails = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_PLAN_DETAILS.METHOD,
    url: routes.GET_AGENT_PLAN_DETAILS.URL,
    data: payload,
  });
};
export const getAgentDIDNumbers = (payload) => {
  return apiClient({
    method: routes.GET_AGENT_DID_NUMBERS.METHOD,
    url: routes.GET_AGENT_DID_NUMBERS.URL,
    data: payload,
  });
};

export const addAgent = (payload) => {
  return apiClient({
    method: routes.ADD_AGENT.METHOD,
    url: routes.ADD_AGENT.URL,
    data: payload,
  });
};

export const editAgent = (payload) => {
  return apiClient({
    method: routes.EDIT_AGENT.METHOD,
    url: routes.EDIT_AGENT.URL,
    data: payload,
  });
};

export const deleteAgent = (payload) => {
  return apiClient({
    method: routes.DELETE_AGENT.METHOD,
    url: routes.DELETE_AGENT.URL,
    data: payload,
  });
};

export const addEmployee = (payload) => {
  return apiClient({
    method: routes.ADD_EMPLOYEE.METHOD,
    url: routes.ADD_EMPLOYEE.URL,
    data: payload,
  });
};

export const editEmployee = (payload) => {
  return apiClient({
    method: routes.EDIT_EMPLOYEE.METHOD,
    url: routes.EDIT_EMPLOYEE.URL,
    data: payload,
  });
};

export const deleteEmployee = (payload) => {
  return apiClient({
    method: routes.DELETE_EMPLOYEE.METHOD,
    url: routes.DELETE_EMPLOYEE.URL,
    data: payload,
  });
};

// --------------
export const activatePlan = (payload) => {
  return apiClient({
    method: routes.ACTIVATE_PLAN.METHOD,
    url: routes.ACTIVATE_PLAN.URL,
    data: payload,
  });
};
export const deActivatePlan = (payload) => {
  return apiClient({
    method: routes.DEACTIVATE_PLAN.METHOD,
    url: routes.DEACTIVATE_PLAN.URL,
    data: payload,
  });
};
export const portingList = (payload) => {
  return apiClient({
    method: routes.PORTING_LIST.METHOD,
    url: routes.PORTING_LIST.URL,
    data: payload,
  });
};
export const portingStatusUpdate = ({ id = '', ...rest }) => {
  return apiClient({
    method: routes.PORTING_STATUS_UPDATE.METHOD,
    url: `${routes.PORTING_STATUS_UPDATE.URL}/${id}`,
    data: rest,
  });
};
export const userLogin = (data) => {
  return apiClient({
    method: routes.USER_LOGIN.METHOD,
    url: `${routes.USER_LOGIN.URL}`,
    data,
  });
};
